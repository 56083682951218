import React, { useState, useEffect } from 'react';
import backendApi from '../../utils/backendApis';
import './Modal.css';

const Modal = ({
  isModalOpen,
  handleCheckClose,
  didWin,
  currentScore,
  highestScore,
  congratulation,
  setCongratulation,
  hearts,
}) => {
  const [newHighestScore, setnewHighestScore] = useState(highestScore);

  useEffect(() => {
    const updatenewHighestScore = async () => {
      try {
        if (didWin && currentScore > newHighestScore) {
          console.log('db에서 가져온 highest score', newHighestScore);
          const response = await backendApi.updatenewHighestScore(currentScore);
          console.log('Highest score updated:', response);
          setnewHighestScore(currentScore);
          setCongratulation(true);
        }
      } catch (error) {
        console.error('Error updating highest score', error);
      }
    };

    updatenewHighestScore();
  }, [isModalOpen, didWin, currentScore]);

  //이 부분 복습하자
  useEffect(() => {
    setnewHighestScore(highestScore);
  }, [highestScore]);

  return (
    isModalOpen && (
      <>
        <div className='modal'>
          {didWin ? (
            congratulation ? (
              <>
                <p>
                  <span className='congratsMessage'>🎉 축하합니다 🎉</span>
                  <br />
                  <br />
                  새로운 기록을 경신하셨어요!
                </p>
                <span className='highestScore'>
                  최고 기록: {newHighestScore}점
                </span>
              </>
            ) : (
              <>
                <div>
                  <p>
                    <span className='congratsMessage'>🎉 축하합니다 🎉</span>
                    <br />
                    게임에서 승리하셨습니다!
                    <br />
                    <br />
                    <br />
                    <span className='myWinScore'>
                      내 점수: {currentScore}점
                    </span>
                  </p>
                  <div className='highestScore2'>
                    <span>내 최고 기록: {newHighestScore}점</span>
                  </div>
                </div>
              </>
            )
          ) : hearts === 0 ? (
            <>
              <div>
                <div className='noHeart'>
                  <span className='gameOver'>Game Over</span>
                  <br />
                  <p>아쉽게도 남은 ❤️가 이제 없어요 ㅠㅠ</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='noMoveLeft'>
                <span className='lostFirstLine'>
                  아쉽게도 게임에서 지셨어요😥
                </span>
                <br />
                <br />
                <span className='lostSecondLine'>
                  <strong>한 번더</strong> 해볼까요?{' '}
                </span>
              </div>
            </>
          )}

          <button className='button' onClick={handleCheckClose}>
            한 번 더 플레이하기
          </button>
        </div>
        {isModalOpen && <div className='overlay' onClick={handleCheckClose} />}
      </>
    )
  );
};

export default Modal;

const API_URI = process.env.REACT_APP_API_URI;

const fetcher = async (url, token, method, params = {}) => {
  const resource =
    method === 'GET' ? `${url}?${new URLSearchParams(params)}` : url;
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} };
  init.method = method;
  init.headers['Content-Type'] = 'application/json';
  init.headers['x-access-token'] = token;
  try {
    const res = await fetch(API_URI + resource, init);
    const data = await res.json();
    return data;
  } catch (err) {
    return null;
  }
};

class BackendApis {
  constructor() {
    this.token = null;
  }

  async login(method = 'POST', params = {}) {
    const result = await fetcher('/login', '', method, params);
    if (result?.status === 200) this.token = result.token;
    return result;
  }

  async updatenewHighestScore(newScore) {
    console.log('update하는중');
    return await fetcher('/game/score-update', this.token, 'POST', {
      highestScore: newScore,
    });
  }

  async updateHeartsLeft(newHearts) {
    return await fetcher('/game/hearts', this.token, 'POST', {
      hearts: newHearts,
    });
  }

  async getHearts() {
    return await fetcher('/game/hearts', this.token, 'GET', {});
  }

  async getHigestScore() {
    return await fetcher('/game/score-update', this.token, 'GET', {});
  }

  async newNickName(method = 'POST', params = {}) {
    const result = await fetcher('/auth/nickname', '', method, params);
    if (result?.status === 201) this.token = result.token;
    return result;
  }

  async getScoreboard() {
    return await fetcher('/game/scoreboard', '', 'GET', {});
  }

  async getNickName() {
    return await fetcher('/auth/nickname', this.token, 'GET', {});
  }

  //   async fetcher(url, token, method, params = {}) {
  //     return await fetcher(url, token, method, params)
  //   }
}

export default new BackendApis();

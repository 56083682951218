import './Score.css';
import React, { useState, useEffect } from 'react';
import blueDonut from '../../images/blue-donut.png';

const Score = ({
  score,
  targetScore,
  aimTarget,
  movesLeft,
  hearts,
  nickName,
  scoreboard,
}) => {
  const [ranking, setRanking] = useState('a');

  const [newHearts, setNewHearts] = useState(hearts);
  useEffect(() => {
    if (scoreboard !== undefined) {
      const ranking =
        scoreboard.findIndex((item) => item.userName === nickName) + 1;
      setRanking(ranking);
    }
  }, [scoreboard]);

  //이 부분 복습하자
  useEffect(() => {
    setNewHearts(hearts);
  }, [hearts]);

  return (
    <>
      <div>
        <div className='hearts'>
          <span
            style={{
              fontFamily: 'Pretendard Variable',
              color: 'black',
              fontSize: '16px',
            }}
          >
            {typeof ranking === 'number' && (
              <>
                {ranking < 10
                  ? `순위 ${ranking}위, 대단한데요?`
                  : `순위 ${ranking}위.. 좀더 해봅시다`}
              </>
            )}
          </span>{' '}
          &nbsp;&nbsp;
          <span style ={{fontFamily : 'Pretendard Variable'}}>
            {nickName} ❤️{newHearts}
          </span>
        </div>
        <div className='score-board'>
          <div className='Rectangle45'>
            <div>
              <span className='score-label'>점수</span>
              <span className='scores'>{score}</span>
            </div>
            <div>
              <span className='goal'>
                목표
                <img
                  src={blueDonut}
                  alt='donutcandy' //size 30px
                  style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '3px',
                  }}
                />
              </span>

              <span>
                <span style={{ color: 'red' }}>{targetScore}</span>/{aimTarget}
              </span>
            </div>
            <div>
              <span>남은이동</span>
              <span style={{ color: 'red' }}>{movesLeft}</span>
            </div>
          </div>
          <div className='Rectangle44' />
        </div>
      </div>
    </>
  );
};

export default Score;

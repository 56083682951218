import './practice_game.css';
import { useEffect, useState, useRef, useCallback } from 'react';
import backendApi from '../../utils/backendApis';
import Score from '../../components/Score/Score';
import RulesModal from '../../components/Rule/Rule';
import RankingModal from '../../components/Ranking/Ranking';
import blueDonut from '../../images/blue-donut.png';
import yellowStar from '../../images/yellow-star.png';
import bread from '../../images/bread.png';
import gingerBread from '../../images/ginger-bread.png';
import pinkJelly from '../../images/pink-jelly.png';
import muffin from '../../images/muffin.png';
import blank from '../../images/blank.png';
import mushroom from '../../images/mushroom.png';
import background from '../../images/backgroundReal_75.png';
import Modal from '../../components/Modal/Modal';
import mushroomSound from '../../music/mushroomSound.wav';
import matchSound from '../../music/matchsound1.wav';
import { useNavigate } from 'react-router-dom';
import backendApis from '../../utils/backendApis';

const useBodyScrollLock = () => {
  const scrollPosition = useRef(0);

  const lockScroll = useCallback(() => {
    // for IOS safari
    scrollPosition.current = window.scrollY;
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition.current}px`;
    document.body.style.width = '100%';
  }, []);

  const openScroll = useCallback(() => {
    // for IOS safari
    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('top');
    document.body.style.removeProperty('width');
    window.scrollTo(0, scrollPosition.current);
  }, []);

  return { lockScroll, openScroll };
};

const width = 8;
const candyColors = [
  blueDonut,
  bread,
  gingerBread,
  pinkJelly,
  muffin,
  yellowStar,
];

const Game = () => {
  const navigate = useNavigate();
  const [nickName, setNickName] = useState('연습게임');

  // Board
  const [currentColorArrangement, setCurrentColorArrangement] = useState([]);

  //rank
  const [scoreboard, setScoreboard] = useState([]);

  // Movement
  const [squareBeingDragged, setSquareBeingDragged] = useState(null);
  const [squareBeingReplaced, setSquareBeingReplaced] = useState(null);
  const [scoreDisplay, setScoreDisplay] = useState(0);
  const squareCoordinatesRef = useRef(null);

  const [movesLeft, setMovesLeft] = useState(20);

  // Target
  const [targetCandy, setTargetCandy] = useState(blueDonut);
  const [targetCandyCount, setTargetCandyCount] = useState(0);
  const [candyAim, setCandyAim] = useState(6);

  const [isGameWin, setIsGameWin] = useState(null);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [isRuleModalOpen, setIsRuleModalOpen] = useState(true);
  const [isRankingModalOpen, setIsRankingModalOpen] = useState(false);

  const [highestScore, setHighestScore] = useState(0);
  const [congratulation, setCongratulation] = useState(false);
  const [hearts, setHearts] = useState(5);

  // Game Rules
  const [mushroomAppear, setMushroomAppear] = useState(false);

  // Background
  // const [mushroomAudio] = useState(new Audio(mushroomSound));
  const [matchAudio] = useState(new Audio(matchSound));
  // const [audio] = useState(new Audio(backgroundMusic));

  //animation Effect
  const [applyFadeInColumns, setApplyFadeInColumns] = useState([]);

  const { lockScroll, openScroll } = useBodyScrollLock();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openRuleModal = () => {
    setIsRuleModalOpen(true);
  };

  const closeRuleModal = () => {
    setIsRuleModalOpen(false);
  };

  const openRankingModal = () => {
    setIsRankingModalOpen(true);
  };

  const closeRankingModal = () => {
    setIsRankingModalOpen(false);
  };

  const gameWin = () => {
    setIsGameWin(true);
  };

  const gameLose = async () => {
    try {
      setIsGameWin(false);
      setHearts((hearts) => hearts - 1);
    } catch (error) {
      console.error('Error in gameLose function :', error);
    }
  };

  const handleCheckClose = async () => {
    try {
      closeModal();
      resetGame();
      setCongratulation(false);
      if (isGameWin || hearts === 0) {
        navigate('/nickname', { state: { score: scoreDisplay } });
      }
    } catch (error) {
      console.log('창이 안 닫힘', error);
    }
  };

  const resetGame = () => {
    setScoreDisplay(0);
    setMovesLeft(20);
    createBoard();
    setTargetCandyCount(0);
    setIsModalOpen(null);
    setIsGameWin(null);
  };

  const fetchHighestScore = async () => {
    try {
      console.log('Fetching highest score...');
      const response = await backendApi.getHigestScore();
      console.log('Response from server:', response);

      if (response.highestScore !== undefined) {
        setHighestScore(response.highestScore);
        console.log('Highest score set:', response.highestScore);
      } else {
        console.error('Response does not contain highestScore property.');
      }
    } catch (error) {
      console.error('Error fetching highest score', error);
    }
  };

  const checkForColumnOfFour = () => {
    for (let i = 0; i <= 39; i++) {
      const columnOfFour = [i, i + width, i + width * 2, i + width * 3];
      const decidedColor = currentColorArrangement[i];
      const isBlank = currentColorArrangement[i] === blank;

      if (
        columnOfFour.every(
          (square) =>
            currentColorArrangement[square] === decidedColor && !isBlank,
        )
      ) {
        if (decidedColor === targetCandy) {
          setTargetCandyCount((count) => count + 4);
        }
        setApplyFadeInColumns(columnOfFour);

        setTimeout(() => {
          setApplyFadeInColumns([]);
        }, 1000);

        setScoreDisplay((score) => score + 4);
        if (!mushroomAppear) {
          setMushroomAppear(true);
        }
        columnOfFour.forEach(
          (square) => (currentColorArrangement[square] = blank),
        );
        return true;
      }
    }
  };

  const checkForRowOfFour = () => {
    for (let i = 0; i <= 60; i++) {
      const rowOfFour = [i, i + 1, i + 2, i + 3];
      const decidedColor = currentColorArrangement[i];
      const notValid = [
        5, 6, 7, 13, 14, 15, 21, 22, 23, 29, 30, 31, 37, 38, 39, 45, 46, 47, 53,
        54, 55,
      ];
      const isBlank = currentColorArrangement[i] === blank;

      if (notValid.includes(i)) continue;

      if (
        rowOfFour.every(
          (square) =>
            currentColorArrangement[square] === decidedColor && !isBlank,
        )
      ) {
        if (decidedColor === targetCandy) {
          setTargetCandyCount((count) => count + 4);
        }

        setApplyFadeInColumns(rowOfFour);

        setTimeout(() => {
          setApplyFadeInColumns([]);
        }, 1000);

        setScoreDisplay((score) => score + 4);
        if (!mushroomAppear) {
          setMushroomAppear(true);
        }
        rowOfFour.forEach(
          (square) => (currentColorArrangement[square] = blank),
        );

        return true;
      }
    }
  };

  const moveIntoSquareBelow = async () => {
    for (let i = 0; i <= 55; i++) {
      const firstRow = [0, 1, 2, 3, 4, 5, 6, 7];
      const isFirstRow = firstRow.includes(i);

      if (
        mushroomAppear === true &&
        isFirstRow &&
        currentColorArrangement[i] === blank
      ) {
        currentColorArrangement[i] = mushroom;
        await setMushroomAppear(false);
      }

      if (isFirstRow && currentColorArrangement[i] === blank) {
        let randomNumber = Math.floor(Math.random() * candyColors.length);
        currentColorArrangement[i] = candyColors[randomNumber];
      }

      if (currentColorArrangement[i + width] === blank) {
        currentColorArrangement[i + width] = currentColorArrangement[i];
        currentColorArrangement[i] = blank;
      }
    }
  };

  const checkForColumnOfThree = () => {
    for (let i = 0; i <= 47; i++) {
      const columnOfThree = [i, i + width, i + width * 2];
      const decidedColor = currentColorArrangement[i];
      const isBlank = currentColorArrangement[i] === blank;

      if (
        columnOfThree.every(
          (square) =>
            currentColorArrangement[square] === decidedColor && !isBlank,
        )
      ) {
        if (decidedColor === targetCandy) {
          setTargetCandyCount((count) => count + 3);
        }
        setApplyFadeInColumns(columnOfThree);

        setTimeout(() => {
          setApplyFadeInColumns([]);
        }, 1000);

        setScoreDisplay((score) => score + 3);
        columnOfThree.forEach(
          (square) => (currentColorArrangement[square] = blank),
        );
        return true;
      }
    }
  };

  const checkForRowOfThree = () => {
    for (let i = 0; i < 64; i++) {
      const rowOfThree = [i, i + 1, i + 2];
      const decidedColor = currentColorArrangement[i];
      const notValid = [
        6, 7, 14, 15, 22, 23, 30, 31, 38, 39, 46, 47, 54, 55, 63, 64,
      ];
      const isBlank = currentColorArrangement[i] === blank;

      if (notValid.includes(i)) continue;

      if (
        rowOfThree.every(
          (square) =>
            currentColorArrangement[square] === decidedColor && !isBlank,
        )
      ) {
        if (decidedColor === targetCandy) {
          setTargetCandyCount((count) => count + 3);
        }
        setApplyFadeInColumns(rowOfThree);

        setTimeout(() => {
          setApplyFadeInColumns([]);
        }, 1000);

        setScoreDisplay((score) => score + 3);
        rowOfThree.forEach(
          (square) => (currentColorArrangement[square] = blank),
        );
        return true;
      }
    }
  };

  const dragStart = (e) => {
    setSquareBeingDragged(e.target);
  };
  const dragDrop = (e) => {
    setSquareBeingReplaced(e.target);
  };
  const dragEnd = () => {
    if (squareBeingDragged && squareBeingReplaced) {
      const squareBeingDraggedId = parseInt(
        squareBeingDragged.getAttribute('data-id'),
      );
      const squareBeingReplacedId = parseInt(
        squareBeingReplaced.getAttribute('data-id'),
      );

      const validMoves = [
        squareBeingDraggedId - 1,
        squareBeingDraggedId - width,
        squareBeingDraggedId + 1,
        squareBeingDraggedId + width,
      ];

      const validMove = validMoves.includes(squareBeingReplacedId);

      if (validMove) {
        const oldArrangement = [...currentColorArrangement];

        currentColorArrangement[squareBeingReplacedId] =
          squareBeingDragged.getAttribute('src');
        currentColorArrangement[squareBeingDraggedId] =
          squareBeingReplaced.getAttribute('src');

        const isAColumnOfFour = checkForColumnOfFour();
        const isARowOfFour = checkForRowOfFour();
        const isAColumnOfThree = checkForColumnOfThree();
        const isARowOfThree = checkForRowOfThree();

        if (
          validMove &&
          (isARowOfThree || isARowOfFour || isAColumnOfFour || isAColumnOfThree)
        ) {
          setSquareBeingDragged(null);
          setSquareBeingReplaced(null);
        } else {
          currentColorArrangement[squareBeingReplacedId] =
            squareBeingReplaced.getAttribute('src');
          currentColorArrangement[squareBeingDraggedId] =
            squareBeingDragged.getAttribute('src');
          setCurrentColorArrangement([...currentColorArrangement]);
        }

        const hasArrayChanged = !oldArrangement.every(
          (value, index) => value === currentColorArrangement[index],
        );

        if (hasArrayChanged) {
          setCurrentColorArrangement([...currentColorArrangement]);
          setMovesLeft((moves) => moves - 1);
          matchAudio.volume = 0.7;
          matchAudio.play();
        }
      }
    }
  };

  const touchStart = (e) => {
    e.preventDefault();
    // setSquareBeingDragged(e.target);
    // document.addEventListener('touchmove', touchMove, { passive: false });
    const touchX = e.touches[0].clientX;
    const touchY = e.touches[0].clientY;
    const touchedImage = document.elementFromPoint(touchX, touchY);

    if (touchedImage && touchedImage.tagName === 'IMG') {
      // touchedImage.style.touchAction = 'none';
      // const element = document.getElementById('yourElementId');
      // element.style.overflowAnchor = 'none';
      const rect = touchedImage.getBoundingClientRect();
      squareCoordinatesRef.current = {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      };
      setSquareBeingDragged(touchedImage);
    }
  };

  const touchMove = (e) => {
    // document.removeEventListener('touchmove', touchMove); // Remove the event listener after touch end
    // e.preventDefault();
    // e.stoppropogation();
    if (squareBeingDragged !== null) {
      // Determine the new position based on touch coordinates
      const touchX = e.touches[0].clientX;
      const touchY = e.touches[0].clientY;

      // Use document.elementFromPoint to find the element at the touch coordinates
      squareBeingDragged.style.pointerEvents = 'none';
      const newSquareBeingReplaced = document.elementFromPoint(touchX, touchY);
      squareBeingDragged.style.pointerEvents = '';

      const offsetX = touchX - squareCoordinatesRef.current.x;
      const offsetY = touchY - squareCoordinatesRef.current.y;
      squareBeingDragged.style.transform = `translate(${offsetX}px, ${offsetY}px)`;

      // Update state if a new square is found
      setSquareBeingReplaced(newSquareBeingReplaced);
    }
  };

  const touchEnd = () => {
    // document.body.style.overflow = ''; // Re-enable scrolling

    if (squareBeingDragged && squareBeingReplaced) {
      squareBeingDragged.style.transform = `translate(0, 0)`;

      const squareBeingDraggedId = parseInt(
        squareBeingDragged.getAttribute('data-id'),
      );
      const squareBeingReplacedId = parseInt(
        squareBeingReplaced.getAttribute('data-id'),
      );

      const validMoves = [
        squareBeingDraggedId - 1,
        squareBeingDraggedId - width,
        squareBeingDraggedId + 1,
        squareBeingDraggedId + width,
      ];

      const validMove = validMoves.includes(squareBeingReplacedId);

      if (validMove) {
        const oldArrangement = [...currentColorArrangement];

        currentColorArrangement[squareBeingReplacedId] =
          squareBeingDragged.getAttribute('src');
        currentColorArrangement[squareBeingDraggedId] =
          squareBeingReplaced.getAttribute('src');

        const isAColumnOfFour = checkForColumnOfFour();
        const isARowOfFour = checkForRowOfFour();
        const isAColumnOfThree = checkForColumnOfThree();
        const isARowOfThree = checkForRowOfThree();

        if (
          validMove &&
          (isARowOfThree || isARowOfFour || isAColumnOfFour || isAColumnOfThree)
        ) {
          setSquareBeingDragged(null);
          setSquareBeingReplaced(null);
        } else {
          currentColorArrangement[squareBeingReplacedId] =
            squareBeingReplaced.getAttribute('src');
          currentColorArrangement[squareBeingDraggedId] =
            squareBeingDragged.getAttribute('src');
          setCurrentColorArrangement([...currentColorArrangement]);
        }

        // squareBeingDragged.style.touchAction = 'auto';

        const hasArrayChanged = !oldArrangement.every(
          (value, index) => value === currentColorArrangement[index],
        );

        if (hasArrayChanged) {
          setCurrentColorArrangement([...currentColorArrangement]);
          setMovesLeft((moves) => moves - 1);
          matchAudio.volume = 0.7;
          matchAudio.play();
        }
      }
    }
  };

  const createBoard = async () => {
    const randomColorArrangement = [];

    for (let i = 0; i < width * width; i++) {
      let randomColor;
      do {
        randomColor =
          await candyColors[Math.floor(Math.random() * candyColors.length)];
      } while (
        i % width > 1 &&
        randomColor === randomColorArrangement[i - 1] &&
        randomColor === randomColorArrangement[i - 2]
      );
      if (i >= width * 2) {
        while (
          randomColor === randomColorArrangement[i - width] &&
          randomColor === randomColorArrangement[i - width * 2]
        ) {
          randomColor =
            await candyColors[Math.floor(Math.random() * candyColors.length)];
        }
      }

      await randomColorArrangement.push(randomColor);
    }

    await setCurrentColorArrangement(randomColorArrangement);
    setScoreDisplay(0);
    // await new Promise((resolve) => setTimeout(resolve, 0)); // Introduce a microtask to wait for state update
  };

  const handleMushroomClick = async () => {
    console.log('Handling Mushroom Click');

    const mushroomIndex = currentColorArrangement.findIndex(
      (candy) => candy === mushroom,
    );
    const removedCandies = [];

    // 같은 Row에 있는 캔디 제거
    const rowStartIndex = mushroomIndex - (mushroomIndex % width);
    const rowEndIndex = rowStartIndex + width;
    for (let i = rowStartIndex; i < rowEndIndex; i++) {
      if (currentColorArrangement[i] === targetCandy) {
        await setTargetCandyCount((count) => count + 1);
      }
      removedCandies.push(i);
    }

    // 같은 column에 있는 캔디 제거
    for (let i = mushroomIndex; i >= 0; i -= width) {
      if (currentColorArrangement[i] === targetCandy) {
        await setTargetCandyCount((count) => count + 1);
      }
      removedCandies.push(i);
    }

    for (let i = mushroomIndex; i < width * width; i += width) {
      if (currentColorArrangement[i] === targetCandy) {
        await setTargetCandyCount((count) => count + 1);
      }
      removedCandies.push(i);
    }

    removedCandies.forEach((index) => {
      setApplyFadeInColumns((prev) => [...prev, index]);
    });

    setTimeout(() => {
      setApplyFadeInColumns([]);
    }, 1000);

    removedCandies.forEach((index) => {
      currentColorArrangement[index] = blank;
    });

    // 버섯이 없앤 캔디 만큼 점수 추가
    const removedCandyCount = currentColorArrangement.filter(
      (candy) => candy === blank,
    ).length;
    setScoreDisplay((score) => score + removedCandyCount);
    // mushroomAudio.volume = 0.7;
    // mushroomAudio.play();
  };

  useEffect(() => {
    // const initializeBoard = async () => {
    //   await createBoard(); // Wait for createBoard to complete
    //   setScoreDisplay(0); // Reset scoreDisplay to 0
    // };
    // initializeBoard();
    createBoard();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      checkForColumnOfFour();
      checkForRowOfFour();
      checkForColumnOfThree();
      checkForRowOfThree();
      moveIntoSquareBelow();
      setCurrentColorArrangement([...currentColorArrangement]);
    }, 100);
    return () => clearInterval(timer);
  }, [
    checkForColumnOfFour,
    checkForRowOfFour,
    checkForColumnOfThree,
    checkForRowOfThree,
    moveIntoSquareBelow,
    currentColorArrangement,
  ]);

  useEffect(() => {
    if (movesLeft === 0) {
      gameLose();
      openModal();
    }

    if (targetCandyCount >= candyAim) {
      gameWin();
      openModal();
    }
  }, [movesLeft, targetCandyCount]);

  useEffect(() => {
    fetchHighestScore();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await backendApis.getScoreboard();
      setScoreboard(res.scoreboard);
    };

    fetchData();
  }, []);

  // useEffect(()=>{

  //   if(!document) return;

  //   document.addEventListener('touchstart', touchStart);
  //   // document.addEventListener('touchmove', touchMove, { passive: true });
  //   document.addEventListener('touchmove',touchMove);
  //   document.addEventListener('touchend', touchEnd);

  //   return () => {
  //     document.removeEventListener('touchstart', touchStart);
  //     document.removeEventListener('touchmove', touchMove);
  //     document.removeEventListener('touchend', touchEnd);
  //   };

  // }, [document, touchStart, touchEnd, touchMove])

  // useEffect(() => {
  //   // Attach event listeners for touch events
  //   document.addEventListener('touchstart', lockScroll, { passive: false });
  //   document.addEventListener('touchmove', lockScroll, { passive: false });
  //   document.addEventListener('touchend', openScroll, { passive: false });

  //   return () => {
  //     // Clean up event listeners
  //     document.removeEventListener('touchstart', lockScroll);
  //     document.removeEventListener('touchmove', lockScroll);
  //     document.removeEventListener('touchend', openScroll);
  //   };
  // }, [lockScroll, openScroll]);

  useEffect(() => {
    const imgElements = document.querySelectorAll(
      '.game img:not([src*="mushroom"])',
    );
    imgElements.forEach((img) => {
      img.addEventListener('touchstart', touchStart);
      img.addEventListener('touchmove', touchMove);
      img.addEventListener('touchend', touchEnd);
    });

    // Clean up event listeners when component unmounts
    return () => {
      imgElements.forEach((img) => {
        img.removeEventListener('touchstart', touchStart);
        img.removeEventListener('touchmove', touchMove);
        img.removeEventListener('touchend', touchEnd);
      });
    };
  }, [touchStart, touchEnd, touchMove]);

  useEffect(() => {
    const imgElements = document.querySelectorAll(
      '.game img:not([src*="mushroom"])',
    );
    imgElements.forEach((img) => {
      img.addEventListener('touchstart', lockScroll, { passive: false });
      img.addEventListener('touchmove', lockScroll, { passive: false });
      img.addEventListener('touchend', openScroll, { passive: false });
    });

    // Clean up event listeners when component unmounts
    return () => {
      imgElements.forEach((img) => {
        img.removeEventListener('touchstart', lockScroll);
        img.removeEventListener('touchmove', lockScroll);
        img.removeEventListener('touchend', openScroll);
      });
    };
  }, [lockScroll, openScroll]);

  useEffect(() => {
    return () => {
      matchAudio.pause();
      matchAudio.currentTime = 0;
    };
  }, []);

  return (
    <div
      className='app'
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: '100% 100%',
      }}
    >
      <div className='container'>
        <Score
          score={scoreDisplay}
          aimTarget={candyAim}
          targetScore={targetCandyCount}
          movesLeft={movesLeft}
          hearts={hearts}
          nickName={nickName}
        />
        <div className='game'>
          {currentColorArrangement.map((candyColor, index) => (
            <img
              key={index}
              src={candyColor}
              alt={candyColor}
              data-id={index}
              draggable={true}
              onDragStart={dragStart}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={(e) => e.preventDefault()}
              onDragLeave={(e) => e.preventDefault()}
              onDrop={dragDrop}
              onDragEnd={dragEnd}
              onClick={() => {
                if (candyColor === mushroom) {
                  handleMushroomClick();
                }
              }}
              className={
                applyFadeInColumns.includes(index) ? 'fade-in-image' : ''
              }
            />
          ))}
        </div>
      </div>

      <button className='btn-gradient' onClick={openRankingModal}>
        👑 순위 보기
      </button>
      <button className='btn-gradient2' onClick={openRuleModal}>
        규칙 설명
      </button>
      <RulesModal open={isRuleModalOpen} close={closeRuleModal} />
      <RankingModal
        open={isRankingModalOpen}
        close={closeRankingModal}
        scoreboard={scoreboard}
      />
      <Modal
        isModalOpen={isModalOpen}
        handleCheckClose={handleCheckClose}
        didWin={isGameWin}
        currentScore={scoreDisplay}
        highestScore={highestScore}
        congratulation={congratulation}
        setCongratulation={setCongratulation}
        hearts={hearts}
      />
    </div>
  );
};

export default Game;

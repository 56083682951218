import './Ranking.css';
import React, { useEffect, useState } from 'react';

const RankingModal = ({ open, close, scoreboard, nickName }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const renderScoreboard = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = scoreboard.slice(startIndex, endIndex);

    return (
      <div className='scoreboard-container2'>
        <div>
          <div className='scoreboard-header2'>
            <span>순위</span>
            <span>닉네임</span>
            <span>점수</span>
          </div>
          {currentPageData.map((item, index) => (
            <div
              key={index}
              className='scoreboard-item2'
              style={{ color: item.userName === nickName ? 'red' : 'inherit' }}
            >
              <span>{index + startIndex + 1}</span>
              <span>{item.userName}</span>
              <span>{item.highestScore}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <>
      {open && (
        <div className='modal2'>
          <div>
            {renderScoreboard()}

            {scoreboard.length > currentPage * itemsPerPage && (
              <button className='nextButton2' onClick={handleNextPage}>
                Next 순위
              </button>
            )}
            {currentPage > 1 && (
              <button className='previousButton2' onClick={handlePreviousPage}>
                Previous
              </button>
            )}
          </div>
        </div>
      )}
      {open && <div className='overlay2' onClick={close} />}
    </>
  );
};

export default RankingModal;

import React from 'react';
import './Rule.css';

const RulesModal = ({ open, close }) => {
  return (
    <>
      {open && (
        <div className='modal3'>
          <div>
            <h2 style={{ textAlign: 'center' }}>규칙</h2>
            <span>
              1) 3개 혹은 4개의 그림을 가로 또는 세로로 일열로 배열하면 점수가 증가해요
            </span>
            <br />
            <br />
            <span>
              2) 목표는 제한된 20번의 이동안에 &nbsp;
              <strong>
                <span style={{ color: 'blue' }}>파란색 도넛을 22개 없애는</span>
              </strong>
              거에요
            </span>
            <br />
            <br />
            <span>
              3) <strong>4개의 그림</strong>을 일열로 배열하면 🍄버섯아이템이
              나와요
            </span>
            <br />
            <br />
            <span>
              4) 🍄버섯을 클릭하면 상,하,좌,우에 있는 모든 그림이 없어지고
              점수가 증가해요
            </span>
            <br />
            <br />
            <span>
              <strong>
                꿀팁 : 🍄버섯을 잘 사용하세요. 단순히 파란색 도넛을 없애는 것
                보다는 다른 그림도 없애면서 점수를 높여 👑순위를 높여보슈!
              </strong>
            </span>
            <br />
            <br />
            <button
              onClick={close}
              style={{ display: 'block', margin: '0 auto' }}
            >
              닫기
            </button>
          </div>
        </div>
      )}
      {open && <div className='overlay3' onClick={close} />}
    </>
  );
};

export default RulesModal;

import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Game from './pages/game/game';
// import Login from './pages/login/login';
import Scoreboard_Nickname from './pages/scoreboard/scoreboard';
import Practice_Game from './pages/practice_game/practice_game';
function App() {
  return (
    <div className='App'>
      <Router>
        <Routes>
          {/* <Route path='/login' element={<Login />} /> */}
          <Route path='/' element={<Navigate to='/practice-game' />} />
          <Route path='/practice-game' element={<Practice_Game />} />
          <Route path='/nickname' element={<Scoreboard_Nickname />} />
          <Route path='/game' element={<Game />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import backendApis from '../../utils/backendApis';
import Scoreboard from '../../components/Scoreboard/Scoreboard';
import './scoreboard.css';

const Scoreboard_Nickname = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState('');

  const newNickNameHandler = async (event) => {
    event.preventDefault();

    if (userName === '') {
      alert('닉네임을 입력해주세요');
      return;
    } else {
      const res = await backendApis.newNickName('POST', {
        userName: userName,
      });
      if (res.status === 201) {
        alert(
          '닉네임이 생성 되었어요! 이제 본격적으로 게임을 하고 내 순위를 높여볼까요?',
        );
        const obj = location;
        const win_score = obj.state.score;
        console.log('win_score', win_score);
        const update = await backendApis.updatenewHighestScore(win_score);
        navigate('/game', {
          state: { userName },
        });
      } else {
        if (res.status === 409) {
          alert('이미 존재하는 닉네임입니다!');
        } else {
          alert('문제가 발생했습니다 :(');
        }
      }
    }
  };

  useEffect(() => {
    console.log('location', location);
  }, []);

  return (
    <div className='parent'>
      <div>
      <div>
      <h1 className='ranking-heading' style={{ textAlign: 'center' }}>
        👑 Ranking
      </h1>
    
      <Scoreboard />
      </div>
      <div>
      <div style={{ padding: '20px' }}>
        <br/><br/>
        <span style={{ fontSize: 20 }}>
          연습게임 완료!&nbsp;
          <strong>
            닉네임을 입력해주시면 본격적인 게임이 시작됩니다.
          </strong>
        </span>
      </div>
      <div className='center'>
        <form onSubmit={newNickNameHandler}>
          <div className='nickName_location'>
            <input
              className='nickName'
              type='text'
              name='name'
                placeholder='닉네임을 입력해주세요'
                style={{ fontSize: '18pt' }}
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
            />
            <button class='button-7' role='button' type='submit'>
              확인
            </button>
          </div>
        </form>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Scoreboard_Nickname;

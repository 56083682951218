// Scoreboard.js
import React, { useEffect, useState } from 'react';
import './Scoreboard.css';
import backendApis from '../../utils/backendApis';

const Scoreboard = () => {
  const [scoreboard, setScoreboard] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      const res = await backendApis.getScoreboard();
      setScoreboard(res.scoreboard);
    };

    fetchData();
  }, []); // Fetch scoreboard when the component mounts

  const renderScoreboard = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPageData = scoreboard.slice(startIndex, endIndex);

    return (
      <div className='scoreboard-container'>
        <div>
          <div className='scoreboard-header'>
            <span>순위</span>
            <span>닉네임</span>
            <span>점수</span>
          </div>
          {currentPageData.map((item, index) => (
            <div key={index} className='scoreboard-item'>
              <span>{index + startIndex + 1}</span>
              <span>{item.userName}</span>
              <span>{item.highestScore}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div>
      {renderScoreboard()}
      {scoreboard.length > currentPage * itemsPerPage && (
        <button className='nextButton' onClick={handleNextPage}>
          Next
        </button>
      )}
      {currentPage > 1 && (
        <button className='previousButton' onClick={handlePreviousPage}>
          Previous
        </button>
      )}
    </div>
  );
};

export default Scoreboard;
